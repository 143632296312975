<template>
  <div class="smis-block">
    <div class="smis-block__header">
      <h2 class="smis-block__title">СМИ о нас</h2>
      <router-link :to="{ name: 'smi' }" class="smis-block__link">Показать все</router-link>
    </div>
    <div class="smis-block__body">
      <SmisItemCard v-for="(item, i) in data" :key="i" :data="item" />
    </div>
    <SmiSlider :hot_links="hot_links" />
  </div>
</template>

<script>
import SmisItemCard from "components/SmisItemCard.vue";
import SmiSlider from "components/SmiSlider.vue";

export default {
  name: "SmisAboutComponent",
  components: { SmiSlider, SmisItemCard },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    hot_links: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus">
.smis-block {
  padding: 60px 100px
  background var(--gray-1)
  display flex
  flex-direction column

  &__white {
    background var(--white)
  }

  +below(1400px) {
    padding: 60px 80px;
  }

  +below(1100px) {
    padding: 30px 15px;
  }

  &__body {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px

    +below(1100px) {
      font-size: 1.25rem;
    }

    +below(780px) {
      grid-template-columns 1fr
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 35px;
    color: var(--blue);

    +below(1100px) {
      font-size: 1.25rem;
    }
  }

  &__header {
    display flex
    justify-content space-between
    align-items center
    padding-bottom: 30px;
  }

  &__link {
    font-size: 1.25rem
    color: var(--blue);

    +below(628px) {
      font-size: 1rem
    }
  }
}
</style>
